import { Inject, Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { AuthModel } from 'auth-module';
import { Environment, ENVIRONMENT, ICompany, SentryService } from 'common-module';
import { RouterModel } from 'router-module';
import {
  asyncScheduler,
  catchError,
  combineLatest,
  debounceTime,
  delay,
  distinctUntilChanged,
  EMPTY,
  filter,
  fromEvent,
  interval,
  map,
  merge,
  mergeMap,
  observeOn,
  of,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';
import { GlobalService } from '../../services/global.service';
import { WindowService } from '../../window.service';
import { GlobalModel } from './model';
import { combineLatestForFrame } from 'shared';
import { CsvProcessStatus, IUserCsvUploadFirestoreEntry } from 'types';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NotificationModel, NotificationType } from 'notification-module';
import { Action } from '@ngrx/store';

const timeoutInMilliseconds = 5 * 60000;

@Injectable({
  providedIn: 'root',
})
export class GlobalEffects {
  // TODO: Remove this initial and check if the adminAppCompanyId and clientAppCompanyId are null and
  // set them
  initial = true;

  firebaseCollectionSubscription = combineLatest([
    this.authModel.user$,
    this.authModel.isUserAdmin$,
    this.authModel.selectors.isDeskbirdAdmin$,
  ])
    .pipe(
      filter(([user, isAdmin, isDbAdmin]) => !!user && (isAdmin || isDbAdmin)),
      switchMap(() =>
        combineLatestForFrame([
          this.globalModel.selectors.adminAppCompany$.pipe(distinctUntilChanged((a, b) => a?.id === b?.id)),
          this.globalModel.actions.listen.recheckUserCsvImportStatus$.pipe(startWith(null)),
        ]).pipe(
          takeUntil(this.authModel.actions.listen.logout$),
          catchError((err) => {
            console.log(err);
            return [];
          })
        )
      ),
      map(([val]) => val),
      filter((val): val is ICompany => !!val),
      switchMap((company) =>
        this.firestore
          .collection<IUserCsvUploadFirestoreEntry>('importCsv')
          .doc(company.uuid)
          .valueChanges()
          .pipe(takeUntil(this.authModel.actions.listen.logout$))
      ),
      withLatestFrom(this.globalModel.selectors.importUserCsvData$)
    )
    .subscribe(([entry, importUserCsvData]): void => {
      const noEntry = !entry;
      const cancelledEntry = !!entry && typeof entry.importCSVId === 'number' && entry.importStatus === null;
      if (importUserCsvData && (noEntry || cancelledEntry)) {
        return void this.globalModel.actions.dispatch.checkUserCsvImportStatusCleanup();
      }
      if (noEntry || cancelledEntry) {
        return void this.globalModel.actions.dispatch.checkUserCsvImportStatusSuccess({
          processState: null,
          importCsvId: null,
          fileSize: null,
          fileName: null,
          uploadDate: null,
        });
      }
      this.globalModel.actions.dispatch.checkUserCsvImportStatus(entry);
    });

  setIsDeskbirdAdmin$ = createEffect(() =>
    this.authModel.selectors.isDeskbirdAdmin$.pipe(
      switchMap((isDeskbirdAdmin) => [this.globalModel.actions.create.setIsDeskbirdAdmin({ isDeskbirdAdmin })])
    )
  );

  setUser$ = createEffect(
    () =>
      merge(
        this.authModel.actions.listen.setUser$.pipe(
          filter((payload) => !!payload.user),
          map((payload) => ({ user: payload.user }))
        ),
        this.authModel.actions.listen.loadAuthDataSuccess$.pipe(map((payload) => ({ user: payload.user }))),
        this.authModel.actions.listen.loginSuccess$.pipe(map((payload) => ({ user: payload.user }))),
        this.authModel.actions.listen.microsoftSignInSuccess$.pipe(map((payload) => ({ user: payload.user }))),
        this.authModel.actions.listen.microsoftTeamsSignInSuccess$.pipe(map((payload) => ({ user: payload.user }))),
        this.authModel.actions.listen.googleSignInSuccess$.pipe(map((payload) => ({ user: payload.user }))),
        this.authModel.actions.listen.updateUserSuccess$.pipe(map((payload) => ({ user: payload.updates }))),
        this.authModel.actions.listen.logoutSuccess$.pipe(map(() => ({ user: null })))
      ).pipe(
        distinctUntilChanged((prev, curr) => prev?.user?.id === curr?.user?.id),
        tap(({ user }) => {
          this.sentry.updateUser(user);
        })
      ),
    { dispatch: false }
  );

  loadInvoiceBanner$ = createEffect(() =>
    this.globalModel.actions.listen.loadInvoiceBanner$.pipe(
      switchMap(({ companyId }) => {
        return this.globalService.loadInvoiceBanner(companyId).pipe(
          switchMap((invoiceBanner) => [this.globalModel.actions.create.loadInvoiceBannerSuccess({ invoiceBanner: invoiceBanner.banner })]),
          catchError((error) => {
            return [this.globalModel.actions.create.loadInvoiceBannerFailure({ error })];
          })
        );
      })
    )
  );

  fetchInvoiceBannerOnSettingCompanyIdForAdmin$ = createEffect(() =>
    this.globalModel.actions.listen.setAdminAppCompanyId$.pipe(
      filter(({ companyId }) => !!companyId),
      switchMap((payload) =>
        this.routerModel.isAdminAppEnv$.pipe(
          filter(Boolean),
          switchMap(() => {
            const companyId = payload.companyId;
            return [this.globalModel.actions.create.loadInvoiceBanner({ companyId: companyId || '' })];
          })
        )
      )
    )
  );

  setUserOfficeAndCompanyData$ = createEffect(() =>
    merge(
      this.authModel.actions.listen.setUser$.pipe(
        filter((payload) => !!payload.user),
        map((payload) => ({ user: payload.user }))
      ),
      this.authModel.actions.listen.loadAuthDataSuccess$.pipe(map((payload) => ({ user: payload.user }))),
      this.authModel.actions.listen.loginSuccess$.pipe(map((payload) => ({ user: payload.user }))),
      this.authModel.actions.listen.microsoftSignInSuccess$.pipe(map((payload) => ({ user: payload.user }))),
      this.authModel.actions.listen.microsoftTeamsSignInSuccess$.pipe(map((payload) => ({ user: payload.user }))),
      this.authModel.actions.listen.googleSignInSuccess$.pipe(map((payload) => ({ user: payload.user }))),
      this.authModel.actions.listen.updateUserSuccess$.pipe(map((payload) => ({ user: payload.updates }))),
      this.authModel.actions.listen.logoutSuccess$.pipe(map(() => ({ user: null })))
    ).pipe(
      distinctUntilChanged(
        (prev, curr) => prev?.user?.companyId === curr?.user?.companyId && prev?.user?.primaryOfficeId === curr?.user?.primaryOfficeId
      ),
      tap(({ user }) => {
        if (this.initial === false) {
          if (user === null) {
            this.initial = true;
          }
        }
      }),
      filter(({ user }) => !!user),
      switchMap(({ user }) => {
        const companyId = user!.companyId || null;
        const officeId = user!.primaryOfficeId || null;
        const hasNoCompany = !companyId;
        const hasNoPrimaryOffice = !officeId;

        const actions: Action[] = [
          ...(this.initial
            ? [
                this.globalModel.actions.create.setAdminAppCompanyId({ companyId }),
                this.globalModel.actions.create.setClientAppCompanyId({ companyId }),
              ]
            : []),
          this.globalModel.actions.create.noCompany({ hasNoCompany }),
          this.globalModel.actions.create.setNoPrimaryOffice({ hasNoPrimaryOffice }),
        ];

        if (typeof companyId === 'string') {
          const payload = this.initial
            ? {
                // NOTE: initially we need to select the primary office after all of the offices have loaded
                selectedOfficeId: user?.primaryOfficeId,
                companyId,
              }
            : { companyId };
          const loadOffices = this.globalModel.actions.create.loadOffices(payload);
          actions.push(loadOffices);
        }

        return merge(
          actions,
          this.globalModel.actions.listen.loadOfficesSuccess$.pipe(
            take(1),
            switchMap(({ offices }) => {
              const isActiveOffice = offices.find((o) => o.id === user?.primaryOfficeId && o.isActive);
              if (isActiveOffice) {
                return [];
              }
              return [this.globalModel.actions.create.setNoPrimaryOffice({ hasNoPrimaryOffice: true })];
            })
          )
        );
      }),
      tap(() => {
        this.initial = false;
      })
    )
  );

  clearUserOfficeAndCompanyData$ = createEffect(() =>
    merge(
      this.authModel.actions.listen.logoutSuccess$,
      this.authModel.actions.listen.setUser$.pipe(filter((payload) => !payload.user))
    ).pipe(
      switchMap(() => {
        return [
          this.globalModel.actions.create.noCompanyCleanup(),
          this.globalModel.actions.create.setNoPrimaryOfficeCleanup(),
          this.globalModel.actions.create.loadCompanyCleanup(),
          this.globalModel.actions.create.loadCompaniesCleanup(),
          this.globalModel.actions.create.loadOfficesCleanup(),
          this.globalModel.actions.create.setAdminAppCompanyId({ companyId: null }),
          this.globalModel.actions.create.setClientAppCompanyId({ companyId: null }),
          this.globalModel.actions.create.setAdminAppOfficeId({ officeId: null }),
          this.globalModel.actions.create.setClientAppOfficeId({ officeId: null }),
        ];
      })
    )
  );

  fetchCompanyOnSettingCompanyIdForAdmin$ = createEffect(() =>
    this.globalModel.actions.listen.setAdminAppCompanyId$.pipe(
      filter(({ companyId }) => !!companyId),
      // Fetch company info only IF:
      // 1. We are inside the Admin Part of the app
      // 2. The company is different than the company we are part of or the company is not loaded
      switchMap((payload) =>
        this.routerModel.isAdminAppEnv$.pipe(
          withLatestFrom(this.authModel.selectors.isDeskbirdAdmin$, this.authModel.user$, this.globalModel.selectors.adminAppCompany$),
          filter(
            ([val, isDeskbirdAdmin, user, adminAppCompany]) =>
              !!val &&
              ((isDeskbirdAdmin && payload.companyId !== user?.companyId) || (!adminAppCompany && payload.companyId === user?.companyId))
          ),
          take(1),
          switchMap(() => {
            const companyId = payload.companyId as string;
            return [this.globalModel.actions.create.loadCompany({ companyId: companyId as string })];
          })
        )
      )
    )
  );

  fetchOfficeOnSettingOfficeId$ = createEffect(() =>
    merge(this.globalModel.actions.listen.setAdminAppOfficeId$, this.globalModel.actions.listen.setClientAppOfficeId$).pipe(
      filter(({ officeId }) => !!officeId),
      switchMap((payload) =>
        this.globalModel.selectors.isFetchingOffices$.pipe(
          take(1),
          switchMap((isFetchingOffices) => {
            if (isFetchingOffices) {
              return [];
            }
            const officeId = payload.officeId as string;
            return [this.globalModel.actions.create.loadOffice({ officeId: officeId as string })];
          })
        )
      )
    )
  );

  loadCompany = createEffect(() =>
    this.globalModel.actions.listen.loadCompany$.pipe(
      mergeMap(({ companyId }) => {
        return this.globalService.loadCompany(companyId).pipe(
          takeUntil(this.globalModel.actions.listen.loadCompanyCancel$.pipe(filter((payload) => payload.companyId === companyId))),
          switchMap((company) => [this.globalModel.actions.create.loadCompanySuccess({ company })]),
          catchError((error) => {
            return [this.globalModel.actions.create.loadCompanyFailure({ error, companyId })];
          })
        );
      })
    )
  );

  loadCompanies = createEffect(() =>
    this.globalModel.actions.listen.loadCompanies$.pipe(
      switchMap(() => {
        return this.globalService.loadCompanies().pipe(
          takeUntil(this.globalModel.actions.listen.loadCompaniesCancel$),
          switchMap(({ results: companies }) => [this.globalModel.actions.create.loadCompaniesSuccess({ companies })]),
          catchError((error) => {
            return [this.globalModel.actions.create.loadCompaniesFailure({ error })];
          })
        );
      })
    )
  );

  loadOffices = createEffect(() =>
    this.globalModel.actions.listen.loadOffices$.pipe(
      mergeMap((payload) => {
        return this.globalService.loadOffices(payload.companyId).pipe(
          takeUntil(merge(this.globalModel.actions.listen.loadOfficesCancel$, this.authModel.actions.listen.logout$)),
          switchMap(({ results: offices }) => {
            return [
              this.globalModel.actions.create.loadOfficesSuccess({
                offices,
                selectedOfficeId: payload.selectedOfficeId,
                timestamp: payload.timestamp,
              }),
            ];
          }),
          catchError((error) => {
            return [this.globalModel.actions.create.loadOfficesFailure({ error, timestamp: payload.timestamp })];
          })
        );
      })
    )
  );

  loadOffice = createEffect(() =>
    this.globalModel.actions.listen.loadOffice$.pipe(
      mergeMap(({ officeId }) => {
        return this.globalService.loadOffice(officeId).pipe(
          takeUntil(this.globalModel.actions.listen.loadOfficeCancel$.pipe(filter((payload) => payload.officeId === officeId))),
          switchMap((office) => [this.globalModel.actions.create.loadOfficeSuccess({ office })]),
          catchError((error) => {
            return [this.globalModel.actions.create.loadOfficeFailure({ error, officeId })];
          })
        );
      })
    )
  );

  init$ = createEffect(() =>
    ![Environment.DEVELOPMENT, Environment.DEBUG_PRODUCTION, Environment.DEBUG_STAGING].includes(this.environment)
      ? interval(timeoutInMilliseconds).pipe(
          debounceTime(1000),
          observeOn(asyncScheduler),
          withLatestFrom(this.routerModel.selectors.url$),
          filter(([, url]) => !url.includes('offline')),
          map(() => this.globalModel.actions.create.loadAppInfo({}))
        )
      : EMPTY
  );

  loadAppInfo$ = createEffect(() =>
    this.globalModel.actions.listen.loadAppInfo$.pipe(
      filter(
        () =>
          !this.windowService.window.origin.includes('localhost') &&
          ![Environment.DEVELOPMENT, Environment.DEBUG_PRODUCTION, Environment.DEBUG_STAGING].includes(this.environment)
      ),
      switchMap((payload) => {
        const failureCount = payload?.failureCount || 0;
        return this.globalService.loadAppInfo().pipe(
          switchMap((text: string) => {
            const body = document.body.innerHTML;
            const re = /<script[\s\S]*?>[\s\S]*?<\/script>/g;
            let scriptMatch = re.exec(body);
            const currentScriptNames = [];
            while (scriptMatch !== null) {
              const resourceMatch = /src="((runtime|polyfills|scripts|main)(.*?)\.js)"/.exec(scriptMatch[0]);
              if (Array.isArray(resourceMatch) && resourceMatch.length > 1) {
                const [, scriptName] = resourceMatch;
                currentScriptNames.push(scriptName);
              }
              scriptMatch = re.exec(body);
            }

            const scriptNames = typeof text === 'string' ? (JSON.parse(text) as string[]) : Array.isArray(text) ? text : [];
            for (const currentScriptName of currentScriptNames) {
              const scriptIsMissing = !scriptNames.includes(currentScriptName);
              if (scriptIsMissing) {
                console.log(currentScriptName + 'missing! Reloading...', scriptNames, currentScriptNames);
                return [this.globalModel.actions.create.reloadApplication()];
              }
            }

            const actions: any[] = [this.globalModel.actions.create.loadAppInfoSuccess()];
            if (failureCount !== 0) {
              actions.push(this.globalModel.actions.create.reloadApplication());
            }
            return actions;
          }),
          catchError((error) => {
            const actions: any[] = [this.globalModel.actions.create.loadAppInfoFailure({ error })];
            if (failureCount === 5) {
              actions.push(this.routerModel.actions.create.navigateByUrl({ url: '/offline' }));
            }
            return merge(actions, of(this.globalModel.actions.create.loadAppInfo({ failureCount: failureCount + 1 })).pipe(delay(5000)));
          })
        );
      })
    )
  );

  reloadApplication$ = createEffect(
    () =>
      this.globalModel.actions.listen.reloadApplication$.pipe(
        switchMap(() =>
          this.globalModel.selectors.isAppIdle$.pipe(
            filter((isAppIdle) => isAppIdle === false),
            take(1),
            tap(() => this.windowService.reloadWindow())
          )
        )
      ),
    { dispatch: false }
  );

  online$ = this.windowService.window
    ? createEffect(() =>
        fromEvent(this.windowService.window, 'online').pipe(
          switchMap(() =>
            this.authModel.isLogged$.pipe(
              take(1),
              withLatestFrom(this.routerModel.selectors.previousOutlets$, this.routerModel.selectors.previousQueryParams$)
            )
          ),
          switchMap(([isLogged, outlets, queryParams]) => {
            if (!isLogged) {
              return [
                this.globalModel.actions.create.loadAppInfo({}),
                this.routerModel.actions.create.navigate({ commands: ['/login'], extras: { queryParamsHandling: 'preserve' } }),
              ];
            }

            if (outlets.primary.includes('error') || (outlets.primary.length === 1 && outlets.primary[0] === '')) {
              return [
                this.globalModel.actions.create.loadAppInfo({}),
                this.routerModel.actions.create.navigate({ commands: ['/default'], extras: { queryParamsHandling: 'preserve' } }),
              ];
            }

            return [
              this.globalModel.actions.create.loadAppInfo({}),
              this.routerModel.actions.create.navigate({ commands: [{ outlets }], extras: { queryParams } }),
            ];
          })
        )
      )
    : null;

  offline$ = this.windowService.window
    ? createEffect(() =>
        fromEvent(this.windowService.window, 'offline').pipe(
          switchMap(() => [this.routerModel.actions.create.navigateByUrl({ url: '/offline' })])
        )
      )
    : null;

  checkUpload = createEffect(() =>
    combineLatest([this.authModel.user$, this.authModel.isUserAdmin$, this.authModel.selectors.isDeskbirdAdmin$]).pipe(
      filter(([user, isAdmin, isDbAdmin]) => !!user && (isAdmin || isDbAdmin)),
      switchMap(() =>
        this.globalModel.actions.listen.checkUserCsvImportStatus$.pipe(
          switchMap(({ importCSVId }) =>
            combineLatest([this.globalService.checkUploadStatus(importCSVId), this.globalModel.selectors.clientAppCompanyId$]).pipe(
              takeUntil(this.authModel.actions.listen.logout$),
              mergeMap(([response, companyId]) => {
                if (response.success) {
                  if (response.data.results.importCSVStatus === CsvProcessStatus.Done) {
                    this.notificationModel.actions.dispatch.showNotification({
                      data: $localize`:@@user-module|csv-import|users-were-successfully-updated:Users were successfully updated`,
                      link: {
                        label: $localize`:@@user-module|csv-import|go-to-users-page:Go to users page`,
                        route: `admin/company/${companyId}/user/list/registered/import-users`,
                      },
                      notificationType: NotificationType.SUCCESS,
                    });
                  } else if (
                    [CsvProcessStatus.Failed, CsvProcessStatus.PartiallyDone, CsvProcessStatus.UploadFailed].includes(
                      response?.data?.results?.importCSVStatus
                    )
                  ) {
                    this.notificationModel.actions.dispatch.showNotification({
                      data: $localize`:@@user-module|csv-import|an-error-occured:An error occured while trying to update users`,
                      link: {
                        label: $localize`:@@common|review:Review`,
                        route: `admin/company/${companyId}/user/list/registered/import-users`,
                      },
                      notificationType: NotificationType.ERROR,
                    });
                  }

                  return [
                    this.globalModel.actions.create.checkUserCsvImportStatusSuccess({
                      processState: response.data.results,
                      importCsvId: importCSVId,
                      fileName: response.data.fileName,
                      fileSize: response.data.fileSize,
                      uploadDate: response.data.createdAt,
                    }),
                  ];
                }

                return [
                  this.globalModel.actions.create.checkUserCsvImportStatusFailure({ error: new Error('check upload status failure') }),
                ];
              }),
              catchError((error: Error) => {
                this.globalModel.selectors.adminAppCompanyId$
                  .pipe(
                    take(1),
                    map((companyId) => {
                      this.notificationModel.actions.dispatch.showNotification({
                        data: $localize`:@@user-module|csv-import|an-error-occured:An error occurred while trying to update users`,
                        link: {
                          label: $localize`:@@common|review:Review`,
                          route: `admin/company/${companyId}/user/list/registered/import-users`,
                        },
                        notificationType: NotificationType.ERROR,
                      });
                    })
                  )
                  .subscribe();

                return [this.globalModel.actions.create.checkUserCsvImportStatusFailure({ error })];
              })
            )
          )
        )
      )
    )
  );

  updateAllowsUsersToManageOfficeRoles = createEffect(() =>
    this.globalModel.actions.listen.updateAllowsUsersToManageOfficeRoles$.pipe(
      switchMap(({allowsUsersToManageOfficeRoles, companyId}) =>
        this.globalService.updateAllowsUsersToManageOfficeRoles(companyId, allowsUsersToManageOfficeRoles).pipe(
          switchMap(() => [
            this.notificationModel.actions.create.showNotification({
              data: $localize`: @@user-module|user-settings-success:User settings updated successfully`,
              notificationType: NotificationType.SUCCESS,
            }),
            this.authModel.actions.create.loadAuthData({}),
            this.globalModel.actions.create.loadCompany({ companyId })]),
          catchError(() => {
            return [
              this.authModel.actions.create.sendSlackDataFailure(),
              this.notificationModel.actions.create.showNotification({
                data: $localize`: @@user-module|user-settings-failure:Error updating user settings`,
                notificationType: NotificationType.ERROR,
              }),
            ];
          })
        ))
    ));

  constructor(
    private globalModel: GlobalModel,
    private authModel: AuthModel,
    private globalService: GlobalService,
    private routerModel: RouterModel,
    private sentry: SentryService,
    private windowService: WindowService,
    private firestore: AngularFirestore,
    private notificationModel: NotificationModel,
    @Inject(ENVIRONMENT) private environment: Environment
  ) {}
}
