import { Environment, firebaseProductionConfig } from 'common-module';

// Used to dynamically set the sentry sample rate for routes beginning with the key
const sentryDynamicSampleRate: { [key: string]: number } = {
  '/admin': 0.2,
  '/analytics': 1,
};

export const environment = {
  env: Environment.PRODUCTION,
  firebase: firebaseProductionConfig,
  apiURL: 'https://api.deskbird.com',
  appURL: 'https://app.deskbird.com',
  publicApiUrl: 'https://deskbird-public-api-e3n4numd7a-ey.a.run.app',
  backendSlackLoginUrl: 'https://europe-west3-deskbird-bbe72.cloudfunctions.net/slack/internal/link',

  // Sentry configuration
  sentrySampleRate: 0.01,
  sentryRelease: '46e022417f13abef09c40f58fc1f0a54e0473daf',
  sentryDynamicSampleRate,
  // End Sentry configuration

  // Google Tag Manager
  gtmId: 'GTM-MG6ZS9M',
  gtmAuth: 'czdy6S3er9U4oUyrfdvwew',
  gtmPreview: 'env-1',
  // End Google Tag Manager

  // Customer.io configuration
  customerIoSiteId: '8b807112883f26cc3295', // deskbird [Production]
  customerIoUseInAppMessages: true,
  // End Customer.io configuration
};
